import { AuthBindings } from "@refinedev/core";
import { notification } from "antd";
import axios from "axios";
import i18n from "i18n";
import { IUser } from "types";

export const TOKEN_KEY = "refine-auth";
export const baseURL = "https://api.checkyay.com";
// export const baseURL = "https://api-dev.checkyay.com";
export const basePDFURL = "https://pdf.checkyay.com/api/pdf?env=prod";
// export const basePDFURL = "https://pdf.checkyay.com/api/pdf?env=dev";
export const apiService = axios.create({
  baseURL: baseURL,
});

const t = (e: string) => i18n.t(e) || "";

export const authProvider: AuthBindings = {
  login: async ({ username, email, password }) => {
    if ((username || email) && password) {
      try {
        var res = await apiService.post<{ accessToken: string }>(
          "/admin.identity.login",
          {
            email: email || username,
            password: password,
          }
        );
        if (res.data.accessToken) {
          apiService.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${res.data.accessToken}`;
          localStorage.setItem(TOKEN_KEY, res.data.accessToken);

          const user = await apiService.post<IUser>("/admin.identity.me");

          if (user.data?.userId) {
            localStorage.setItem("ROLE_NAME", user.data?.role?.name);
          }

          return {
            success: true,
            redirectTo: "/",
          };
        }
      } catch (e: any) {
        let msg = e.response.data.message;
        if (e.response.data.statusCode == 403) {
          msg = "NO_PERMISSION";
        }
        return {
          success: false,
          error: {
            name: (e.response?.data?.messages || [])
              .map((a: any) => a.msg)
              .join(", "),
            message: msg ? t("ERROR." + msg) : t("common.error"),
          },
        };
      }
    }

    return {
      success: false,
      error: {
        name: t("common.error"),
        message: t("common.error_login"),
      },
    };
  },
  forgotPassword: async ({ email }) => {
    if (email) {
      try {
        var res = await apiService.post<{ ok: boolean }>(
          "/admin.identity.forgot_password",
          {
            email: email,
          }
        );
        if (res.data.ok) {
          notification.success({
            message: t("common.send_mail_forgot_success"),
          });
          return {
            success: true,
            redirectTo: "/",
          };
        }
      } catch (e: any) {
        console.log(e);
      }
    }
    return {
      success: false,
      error: {
        name: t("common.error"),
        message: t("common.error"),
      },
    };
  },

  updatePassword: async ({ password, token }) => {
    if (password && token) {
      try {
        var res = await apiService.post<{ ok: boolean }>(
          "/admin.identity.reset_password",
          {
            password: password,
            token: token,
          }
        );
        if (res.data.ok) {
          notification.success({
            message: t("common.update_password_success"),
          });
          return {
            success: true,
            redirectTo: "/",
          };
        } else {
          notification.error({
            message: t("common.update_code_exp"),
          });
          return {
            success: false,
            error: {
              name: t("common.error"),
              message: t("common.error"),
            },
          };
        }
      } catch (e: any) {
        console.log(e);
        let msg = e.response.data.message;
        return {
          success: false,
          error: {
            name: (e.response?.data?.messages || [])
              .map((a: any) => a.msg)
              .join(", "),
            message: msg ? t("ERROR." + msg) : t("common.error"),
          },
        };
      }
    }
    return {
      success: false,
      error: {
        name: t("common.error"),
        message: t("common.error"),
      },
    };
  },

  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    apiService.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const user = await apiService.post<IUser>("/admin.identity.me");

    if (user.data?.userId) {
      localStorage.setItem("ROLE_NAME", user.data?.role?.name);
      return user.data;
    }
    return null;
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
